import React, { useEffect, useState } from "react";
import Container from "../../../utils/ecommerce/EcomContainer";
import { useSelector } from "react-redux";
import AxiosInstance from "../../../axiosInstance";
import Logo from "../../../assets/ecommerce/logo/official_logo.jpg";
import PaidLogo from "../../../assets/ecommerce/icons/paid.png";
import UnPaidLogo from "../../../assets/ecommerce/icons/unpaid.png";

const Invoice = () => {
	const [InvoiceData, setInvoiceData] = useState("");
	const userId = useSelector((state) => state.userInfo.userId);
	useEffect(() => {
		AxiosInstance.get(`/order/last-order-details/${userId}`)
			.then((res) => {
				setInvoiceData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [userId]);
	const totalPrice = Array.isArray(InvoiceData.products)
		? InvoiceData.products.reduce((sum, item) => {
				return sum + item.price * item.quantity;
		  }, 0)
		: 0;

	return (
		<Container>
			<div className='mx-44 mt-10 p-4 bg-white'>
				<div className='flex justify-between items-center py-2'>
					<div>
						<img className='w-[190px]' src={Logo} alt='' />
					</div>
					<div className='bg-primary text-white px-3 py-2 rounded-full'>
						<p className='text-lg'>
							<span className='font-semibold'>Invoice No:</span>{" "}
							{InvoiceData && InvoiceData.order_id}
						</p>
					</div>
				</div>
				<div className='flex justify-between items-center mt-10'>
					<div>
						<p className='text-md font-semibold'>
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.user_name}
						</p>
						<p>
							<span className='text-sm font-medium mt-3'>Phone: </span>
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.phone}
						</p>
						<p>
							<span className='text-sm font-medium mt-3'>Email: </span>
							{InvoiceData && InvoiceData.shipping_address.user_email}
						</p>
						<p>
							<span className='text-sm font-medium mt-3'>Address: </span>438/5,
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.address}
							,{" "}
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.city_name}
							, .{" "}
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.state_name}
							-
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.zip}
							, ,{" "}
							{InvoiceData &&
								InvoiceData.shipping_address &&
								InvoiceData.shipping_address.country_name}
						</p>
					</div>
					<div>
						<p>
							<span className='text-sm font-semibold mt-3'>Order No: </span>
							{InvoiceData && InvoiceData.order_id}
						</p>
						<p>
							<span className='text-sm font-medium mt-3'>Order Date: </span>
							{InvoiceData && InvoiceData.created_date.split("T")[0]}
						</p>
						<p>
							<span className='text-sm font-medium mt-3'>Order Status: </span>
							{InvoiceData && InvoiceData.delivery_status}
						</p>
						<p>
							<span className='text-sm font-medium mt-3'>Payment Status: </span>
							{InvoiceData && InvoiceData.payment_status}
						</p>
					</div>
				</div>
				<div className='mt-3'>
					<hr />
				</div>
				<div className='text-center text-md font-semibold mt-3'>
					<p>Order Summery</p>
				</div>

				<div className='mt-3'>
					<table className=''>
						<thead>
							<tr>
								<th className='text-center'>Image</th>
								<th className='text-center'>Product Name</th>
								<th className='text-center'>Quantity</th>
								<th className='text-center'>Price</th>
								<th className='text-center'>Sub Total</th>
							</tr>
						</thead>
						<tbody>
							{InvoiceData &&
								InvoiceData.products &&
								InvoiceData.products.map((items, index) => {
									return (
										<tr key={index}>
											<td className='  w-[100px] py-1 px-2 border'>
												<div className='w-[40px] h-[40] object-contain'>
													<img
														className='w-full h-full object-cover'
														src={items.main_image}
														alt=''
													/>
												</div>
											</td>
											<td className='text-center w-[500px] py-1  px-2 break-words border'>
												{items.product_name}
											</td>
											<td className='text-center w-[100px] py-1  px-2 border'>
												{items.quantity}
											</td>
											<td className='text-center w-[100px] py-1  px-2 border'>
												{items.price}
											</td>
											<td className='text-center w-[100px] py-1  px-2 border'>
												{items.quantity * items.price}
											</td>
										</tr>
									);
								})}

							<tr>
								<td
									colSpan={4}
									className='text-end  w-[100px] px-2 border py-1'>
									Total
								</td>
								<td className='text-center  w-[100px] px-2 border py-1'>
									{totalPrice || 0}
								</td>
							</tr>
							<tr>
								<td
									colSpan={4}
									className='text-end  w-[100px] px-2 border py-1'>
									Shipping Charge
								</td>
								<td className='text-center  w-[100px] px-2 border py-1'>
									{InvoiceData.shipping_charge}
								</td>
							</tr>
							<tr>
								<td
									colSpan={4}
									className='text-end  w-[100px] px-2 border py-1'>
									Grand Total
								</td>
								<td className='text-center  w-[100px] px-2 border py-1'>
									{totalPrice + InvoiceData.shipping_charge}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className='flex justify-between items-center mt-3 py-2'>
					<div className='flex justify-center px-20 items-center'>
						<img
							className='w-[250px] opacity-50'
							src={
								InvoiceData.payment_status === "Paid" ? PaidLogo : UnPaidLogo
							}
							alt=''
						/>
					</div>
					<div className='bg-primary text-white px-3 py-2 rounded-full'>
						<p className='text-lg'>
							Order No: {InvoiceData && InvoiceData.order_id}
						</p>
					</div>
				</div>
				<div>
					<p className='text-xs text-center text-gray-300'>
						This is a electronic invoice. Please keep and save it anywhere to
						claim warranty
					</p>
				</div>
			</div>
		</Container>
	);
};

export default Invoice;
