import React, { useEffect } from "react";
import Container from "../../../utils/ecommerce/EcomContainer";
import Hero from "./components/hero/Hero";
import Products from "./components/products/Products";
import EcomServiceTab from "./components/service/EcomServiceTab";
import AxiosInstance from "../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../hooks/ecommerce/productSlice/userDataSlice";

const Home = () => {
	const dispatch = useDispatch();
	const userId = useSelector((state) => state.userInfo.userId);

	const handleViewUserData = () => {
		AxiosInstance.get(`/auth/user/${userId}`)
			.then((res) => {
				const data = res.data;
				dispatch(setUserData(data));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		handleViewUserData();
	}, []);
	return (
		<div className=''>
			<Container>
				<Hero />
				<EcomServiceTab />
				<Products />
			</Container>
		</div>
	);
};

export default Home;
