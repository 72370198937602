import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import AxiosInstance from "../../../../axiosInstance";

const AddShippingAddress = ({
	setIsAdd,
	handleShowData,
	handleShowDataAdd,
}) => {
	const userData = useSelector((state) => state.userData.userData);
	const [FormData, setFormData] = useState({
		user_id: "",
		user_name: "",
		user_email: "",
		phone: "",
		address: "",
		city: "",
		zip: "",
		state: "",
		country: "",
	});
	const [LocationData, setLocationData] = useState({
		cities: [],
		states: [],
		countries: [],
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			setFormData({
				user_id: userData.id,
				user_name: userData.full_name,
				user_email: userData.email,
				phone: userData.phone_number,
			});
		}, 100);

		return () => clearTimeout(timer);
	}, [userData]);

	useEffect(() => {
		AxiosInstance.get("/location/all-active-locations")
			.then((res) => {
				setLocationData(res.data || { cities: [], states: [], countries: [] });
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleCountry = (e) => {
		const selectedCountry = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			country: selectedCountry,
		}));
	};

	const handleState = (e) => {
		const selectedState = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			state: selectedState,
		}));
	};

	const handleCity = (e) => {
		const selectedCity = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			city: selectedCity,
		}));
	};

	const handleCancel = () => {
		setIsAdd(false);
	};

	const handleSubmit = () => {
		AxiosInstance.post("/billing/add-billing-address", FormData)
			.then((res) => {
				if (res.status === 201) {
					setIsAdd(false);
					handleShowData();
					handleShowDataAdd();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const nameRef = useRef(null);

	useEffect(() => {
		if (nameRef.current) {
			nameRef.current.value = FormData.user_name;
		}
	}, [FormData.user_name]);

	return (
		<div className='w-full'>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Full Name *</p>
				<input
					name='user_name'
					autoComplete='off'
					className='py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					type='text'
					id='full_name'
					placeholder='Full Name'
					value={FormData.user_name}
					onChange={(e) =>
						setFormData({ ...FormData, user_name: e.target.value })
					}
					ref={nameRef}
				/>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Email *</p>
				<input
					name='user_email'
					autoComplete='off'
					className='py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					type='text'
					id='user_email'
					placeholder='Email'
					value={FormData.user_email}
					onChange={(e) =>
						setFormData({ ...FormData, user_email: e.target.value })
					}
				/>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Phone Number *</p>
				<input
					name='phone'
					autoComplete='off'
					className='py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					type='text'
					id='phone'
					placeholder='Phone Number'
					value={FormData.phone}
					onChange={(e) => setFormData({ ...FormData, phone: e.target.value })}
				/>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Address *</p>
				<textarea
					name='address'
					autoComplete='off'
					className='py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					type='text'
					id='address'
					placeholder='Address'
					value={FormData.address}
					onChange={(e) =>
						setFormData({ ...FormData, address: e.target.value })
					}
				/>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>City *</p>
				<select
					name='city'
					autoComplete='off'
					className='form-control py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					id='city'
					value={FormData.city}
					onChange={handleCity}>
					<option value=''>Select City</option>
					{LocationData.cities &&
						LocationData.cities.length > 0 &&
						LocationData.cities.map((item) => (
							<option key={item.id} value={item.id}>
								{item.city_name}
							</option>
						))}
				</select>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Province *</p>
				<select
					name='state'
					autoComplete='off'
					className='form-control py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					id='state'
					value={FormData.state}
					onChange={handleState}>
					<option value=''>Select Province</option>
					{LocationData.states &&
						LocationData.states.length > 0 &&
						LocationData.states.map((item) => (
							<option key={item.id} value={item.id}>
								{item.state_name}
							</option>
						))}
				</select>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Zip Code*</p>
				<input
					name='zip'
					autoComplete='off'
					className='py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					type='text'
					id='zip'
					placeholder='Zip Code'
					value={FormData.zip}
					onChange={(e) => setFormData({ ...FormData, zip: e.target.value })}
				/>
			</div>
			<div className='mt-5'>
				<p className='text-sm font-medium text-slate-400'>Country *</p>
				<select
					name='country'
					autoComplete='off'
					className='form-control py-2 px-2 w-full focus:outline-none bg-slate-200/50 rounded-md'
					id='country'
					value={FormData.country}
					onChange={handleCountry}>
					<option value=''>Select Country</option>
					{LocationData.countries &&
						LocationData.countries.length > 0 &&
						LocationData.countries.map((item) => (
							<option key={item.id} value={item.id}>
								{item.country_name}
							</option>
						))}
				</select>
			</div>
			<div className='mt-5 flex gap-3 justify-center items-center'>
				<div>
					<button
						onClick={handleSubmit}
						className='px-3 py-1 bg-primary hover:bg-secondary duration-300 text-white rounded-md'>
						Submit
					</button>
				</div>
				<div>
					<button
						onClick={handleCancel}
						className='px-3 py-1 bg-secondary hover:bg-primary duration-300 text-white rounded-md'>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

export default AddShippingAddress;
