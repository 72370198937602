import React from "react";

const AdminDashboard = () => {
	return (
		<div>
			<h1>Admin Dashboard</h1>
		</div>
	);
};

export default AdminDashboard;
