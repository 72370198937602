import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../../../../axiosInstance";
import { useNavigate } from "react-router";
import { setSearchValue } from "../../../../../../hooks/ecommerce/productSlice/searchSlice";

const EcomSearchResult = () => {
	const navigate = useNavigate();
	const [isShowSearchBox, setisShowSearchBox] = useState(false);
	const [SearchData, setSearchData] = useState([]);
	const dispatch = useDispatch();
	const SearchValue = useSelector((state) => state.searchValue.searchValue);
	const searchBoxRef = useRef(null);

	useEffect(() => {
		AxiosInstance.get(`/product/eproduct?product_name=${SearchValue}`)
			.then((res) => {
				setSearchData(res.data);
				setisShowSearchBox(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [SearchValue]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				searchBoxRef.current &&
				!searchBoxRef.current.contains(event.target)
			) {
				setisShowSearchBox(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const shorterProductTitile = (text, maxLength) => {
		if (text.length <= maxLength) {
			return text;
		}
		return text.substring(0, maxLength) + "...";
	};

	const sanitizeProductName = (name) => {
		return name
			.toLowerCase() // Convert to lowercase
			.replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
			.replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
	};

	const handleRedirect = (id, product_name) => {
		navigate(`/product/${id}/${sanitizeProductName(product_name)}`);
		setSearchData([]);
		dispatch(setSearchValue(""));
		setisShowSearchBox(false);
	};

	return (
		<div ref={searchBoxRef}>
			{isShowSearchBox && (
				<div
					className={`bg-white drop-shadow-xl absolute py-2 px-4 w-[540px] ${
						SearchData.length > 5 ? "h-[350px] overflow-y-scroll" : "h-auto"
					} top-[6rem]`}>
					{SearchData &&
						SearchData.map((item, index) => {
							const ProductTitle = shorterProductTitile(item.product_name, 35);
							return (
								<div
									onClick={() => handleRedirect(item.id, item.product_name)}
									key={index}
									className='flex justify-between items-center py-2 px-3 border-b-[1px] cursor-pointer'>
									<div className='flex items-center justify-start gap-x-3'>
										<div className='w-[50px] h-[50px] p-1 overflow-hidden bg-white rounded-lg'>
											<img
												className='w-full h-full object-contain'
												src={item.main_image}
												alt=''
											/>
										</div>
										<div>
											<p className='font-medium'>{ProductTitle}</p>
											<span className='text-[12px] px-2 rounded-full bg-gray-200'>
												{item.category_name}
											</span>
											<span className='text-[12px] px-2 ml-1 rounded-full bg-gray-200'>
												{item.product_brand}
											</span>
										</div>
									</div>
									<div className='text-primary font-medium'>
										<p>€{item.price || 0}.00</p>
									</div>
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
};

export default EcomSearchResult;
