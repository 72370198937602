import React from "react";
import { Link } from "react-router-dom";

const EcomRecentProducts = ({ ProductData }) => {
	const sanitizeProductName = (name) => {
		return name
			.toLowerCase() // Convert to lowercase
			.replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
			.replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
	};

	const shorterProductTitle = (text, maxLength) => {
		if (text.length <= maxLength) {
			return text;
		}
		return text.substring(0, maxLength) + "...";
	};

	return (
		<div className='mt-5'>
			<div className='text-lg font-medium'>
				<p>Recent Products</p>
			</div>
			<div className='w-full h-[400px] overflow-y-scroll pr-2 mt-2'>
				{ProductData &&
					ProductData.slice(35, 45).map((item, index) => {
						const ProductTitle = shorterProductTitle(item.product_name, 30);
						return (
							<Link
								key={index}
								to={`/testproduct/${item.id}/${sanitizeProductName(
									item.product_name
								)}`}
								target='_blank'
								rel='noopener noreferrer'>
								<div className='mt-5 bg-hover/20 px-3 py-2 rounded-md flex justify-between items-center'>
									<div className='flex items-center justify-start gap-2'>
										<div className='w-[50px] h-[50px] overflow-hidden rounded-md bg-white'>
											<img
												className='w-full h-full object-contain'
												src={item.main_image}
												alt=''
											/>
										</div>

										<div>
											<p className='text-sm font-semibold text-primary'>
												{ProductTitle}
											</p>
											<span className='text-[10px] px-2 bg-secondary text-white rounded-full'>
												{item.category_name}
											</span>
											<span className='text-[10px] mx-2 px-2 bg-secondary text-white rounded-full'>
												{item.product_brand}
											</span>
										</div>
									</div>
									<div>
										<p className='text-md text-primary font-semibold'>
											€{item.price}.00
										</p>
									</div>
								</div>
							</Link>
						);
					})}
			</div>
		</div>
	);
};

export default EcomRecentProducts;
