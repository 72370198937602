import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCheck } from "react-icons/ai";
import { setAddressId } from "../../../../hooks/ecommerce/productSlice/shippingAddressSlice";
import AxiosInstance from "../../../../axiosInstance";
import AddShippingAddress from "../../checkout/components/AddShippingAddress";
import { FaSquarePen } from "react-icons/fa6";
import { AiFillDelete } from "react-icons/ai";
import EditShippingAddress from "./EditShippingAddress";

const UserShippingAddress = () => {
	const [isAdd, setIsAdd] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [EditId, setEditId] = useState("");
	const [ShippingData, setShippingData] = useState([]);
	const userId = useSelector((state) => state.userInfo.userId);
	const handleShowData = () => {
		AxiosInstance.get(`/billing/billing-address/${userId}`)
			.then((res) => {
				setShippingData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		AxiosInstance.get(`/billing/billing-address/${userId}`)
			.then((res) => {
				setShippingData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [userId]);
	const handleEdit = (id) => {
		setIsEdit(true);
		setEditId(id);
	};

	const handleDelete = (id) => {
		AxiosInstance.delete(`/billing/billing-address/${id}/${userId}`)
			.then((res) => {
				handleShowData();
			})
			.catch((error) => {
				console.error(error);
			});
	};
	console.log(ShippingData);
	return (
		<div>
			<div className='flex justify-between items-center mb-2'>
				<div className='text-md font-semibold'>
					<p>Shipping Addresses</p>
				</div>
				<div>
					<button
						onClick={() => setIsAdd(!isAdd)}
						className='px-4 py-1 bg-primary text-white rounded-sm hover:bg-secondary text-sm duration-300'>
						Add New Address
					</button>
				</div>
			</div>
			<hr />
			<div className='py-3'>
				{isAdd ? (
					<AddShippingAddress
						handleShowData={handleShowData}
						setIsAdd={setIsAdd}
					/>
				) : (
					<>
						{isEdit ? (
							<EditShippingAddress
								EditId={EditId}
								setIsEdit={setIsEdit}
								handleShowData={handleShowData}
							/>
						) : (
							<>
								{ShippingData && ShippingData.length > 0 ? (
									<>
										<div className='flex gap-x-2 flex-wrap'>
											{ShippingData.map((items, index) => {
												return (
													<div
														key={index}
														className='w-[49.5%] h-auto break-words bg-hover/20 rounded-lg cursor-pointer duration-300 mt-2 px-4 py-2'>
														<div>
															<div className='flex justify-between items-center'>
																<div>
																	<p className='font-medium mb-2'>
																		{items.user_name}
																	</p>
																</div>
																<div className='flex items-center justify-center gap-1 text-lg cursor-pointer'>
																	<button onClick={() => handleEdit(items.id)}>
																		<FaSquarePen color='#016268' />
																	</button>
																	<button
																		onClick={() => handleDelete(items.id)}>
																		<AiFillDelete color='#ff0068' />
																	</button>
																</div>
															</div>

															<div className='flex gap-2 text-sm mb-2'>
																<p>
																	{" "}
																	<span className='font-medium'>
																		Email:
																	</span>{" "}
																	{items.user_email}
																</p>
																<p>
																	{" "}
																	<span className='font-medium'>
																		Phone:
																	</span>{" "}
																	{items.phone}
																</p>
															</div>
															<p className='text-sm'>
																<span className='font-medium'>Address:</span>{" "}
																{items.address}, {items.city_name},{" "}
																{items.state_name}, {items.country_name}
															</p>
														</div>
													</div>
												);
											})}
										</div>
									</>
								) : (
									<div className='py-5 flex justify-center items-center text-md text-gray-400/80'>
										No address found
									</div>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default UserShippingAddress;
