import React, { useEffect, useState } from "react";
import Container from "../../../utils/ecommerce/EcomContainer";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../axiosInstance";
import { setData } from "../../../hooks/ecommerce/productSlice/cartSlice";
import { FiMinus, FiPlus } from "react-icons/fi";
import CashonDelivery from "../../../assets/ecommerce/cart/delivery.png";
import Mollie from "../../../assets/ecommerce/cart/mollie.png";
import ShippingAddress from "./components/ShippingAddress";
import { AiOutlineCheck } from "react-icons/ai";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router";

const Checkout = () => {
	const AddressId = useSelector((state) => state.addressId.AddressId);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const CartData = useSelector((state) => state.cartData.data);
	const userId = useSelector((state) => state.userInfo.userId);
	const [PaymentOption, setPaymentOption] = useState("");

	const shorterProductTitle = (text, maxLength) => {
		if (text.length <= maxLength) {
			return text;
		}
		return text.substring(0, maxLength) + "...";
	};

	const totalPrice = Array.isArray(CartData)
		? CartData.reduce((sum, item) => {
				return sum + item.price * item.quantity;
		  }, 0)
		: 0;

	const totalQuantity = Array.isArray(CartData)
		? CartData.reduce((sum, item) => {
				return sum + item.quantity;
		  }, 0)
		: 0;
	useEffect(() => {
		const paymentId = localStorage.getItem("payment_option");

		setPaymentOption(paymentId);
	}, []);

	const handleMolliePayment = () => {
		AxiosInstance.post("/payment/create-payment", {
			amount: totalPrice.toFixed(2),
			description: `Payment for cart items`,
			redirectUrl: "https://dataimpact.be/payment-success", // URL to handle success
			webhookUrl: "https://ac.apodigi.com/",
		})
			.then((res) => {
				window.location.href = res.data.paymentUrl;
			})
			.catch((error) => {
				console.error("Mollie payment error:", error);
			});
	};

	const handlePaymentOptions = (e) => {
		localStorage.setItem("payment_option", e);
		const paymentOption = localStorage.getItem("payment_option");
		setPaymentOption(paymentOption);
	};
	const handleIsSelected = () => {
		if (PaymentOption === "cash-on-delivery") {
			navigate("/payment-cash-on-delivery");
		} else {
			handleMolliePayment();
		}
	};

	const handleCheckout = () => {
		if (AddressId === "") {
			toast.error("Please Select Shipping Address", {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				transition: Bounce,
			});
		} else {
			if (PaymentOption === null) {
				toast.error("Please Select Payment Option", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					transition: Bounce,
				});
			} else {
				handleIsSelected();
			}
		}
	};

	console.log(PaymentOption);

	return (
		<Container>
			<div className='flex mt-3 gap-5 flex-col lg:flex-row'>
				<div className='flex-1 '>
					<div className=' px-4 py-2 bg-white w-full rounded-lg'>
						<ShippingAddress />
					</div>
					<div className='mt-10'>
						<div className='px-5 bg-white py-5 rounded-lg'>
							<p className='text-lg font-semibold mb-3'>Payment Options</p>
							<div className='flex gap-4 items-center'>
								<div
									onClick={() => handlePaymentOptions("cash-on-delivery")}
									className='relative flex items-center border justify-center flex-col py-3 w-full cursor-pointer hover:border-primary duration-300 rounded-md'>
									<img
										className='w-[150px] h-10 object-contain'
										src={CashonDelivery}
										alt='cash-on-delivery'
									/>
									<p className='font-medium'>Cash On Delivery</p>
									{PaymentOption === "cash-on-delivery" && (
										<div className='w-5 h-5 bg-green-500 flex justify-center items-center text-white absolute -right-2 -top-2 rounded-full'>
											<AiOutlineCheck />
										</div>
									)}
								</div>
								<div
									onClick={() => handlePaymentOptions("Mollie")}
									className='relative flex items-center flex-col border justify-center py-3 w-full cursor-pointer hover:border-primary duration-300 rounded-md'>
									<img
										className='w-[150px] h-10 object-contain'
										src={Mollie}
										alt='mollie'
									/>
									<p className='font-medium'>Mollie</p>
									{PaymentOption === "Mollie" && (
										<div className='w-5 h-5 bg-green-500 flex justify-center items-center text-white absolute -right-2 -top-2 rounded-full'>
											<AiOutlineCheck />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='  flex-shrink-1 flex-grow-1'>
					<div className='bg-white p-4 rounded-lg'>
						<div>
							<div className='mb-5'>
								<div className='flex justify-center items-center text-sm font-medium mb-2'>
									<div className='text-center'>Image</div>
									<div className='flex-1 text-center'>Product Name</div>
									<div>Price</div>
								</div>
								<hr />
							</div>

							{CartData.length > 0 &&
								CartData.map((items, index) => {
									const ProductTitle = shorterProductTitle(
										items.product_name,
										50
									);
									return (
										<div className='flex gap-10 mb-5' key={index}>
											<div>
												<img width={50} src={items.main_image} alt='' />
											</div>
											<div className='flex-1 text-[0.9rem] font-medium'>
												<p>
													{ProductTitle} x{items.quantity}
												</p>
											</div>
											<div> €{items.quantity * items.price || 0}</div>
										</div>
									);
								})}
						</div>
						<hr />
						<div className='flex justify-center items-center text-md mb-10'>
							<div className='text-center'>
								<span className='font-medium'>Total Product:</span>{" "}
								{CartData.length || 0}
							</div>
							<div className='flex-1 text-center'>
								<span className='font-medium'>Total Quantity:</span>{" "}
								{totalQuantity || 0}
							</div>
							<div>
								<span className='font-medium'>Sub Total:</span> €{totalPrice}
							</div>
						</div>

						<div>
							<button
								onClick={handleCheckout} // Trigger payment process on button click
								className='px-3 text-md font-medium py-2 rounded-md cursor-pointer hover:bg-hover duration-300 w-full flex justify-center items-center gap-3 bg-primary text-white'>
								<p>Check out</p>
							</button>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Checkout;
