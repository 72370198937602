import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import AxiosInstance from "../../../../axiosInstance";
import { setData } from "../../../../hooks/ecommerce/productSlice/cartSlice";
import { Bounce, toast } from "react-toastify";

const EcomAddProduct = () => {
	const navigate = useNavigate();
	const Params = useParams();
	const dispatch = useDispatch();
	const userId = useSelector((state) => state.userInfo.userId);
	const [FormData, setFormData] = useState({
		product_id: Params.id,
		user_id: userId,
		quantity: 1,
	});
	const handleViewCart = () => {
		AxiosInstance.get(`/cart/cart/${userId}`)
			.then((res) => {
				dispatch(setData(res.data));
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		handleViewCart();
	}, []);
	const handleSubmit = () => {
		AxiosInstance.post("/cart/add-to-cart", FormData)
			.then((res) => {
				toast.success("Product has been added", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					transition: Bounce,
				});
				handleViewCart();
			})
			.catch((error) => {
				if (!FormData.user_id) {
					toast.success(`${error.message}`, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "light",
						transition: Bounce,
					});
				}
			});
	};
	const handleCheckout = () => {
		navigate("/checkout");
	};
	return (
		<div>
			<div>
				<button
					onClick={handleSubmit}
					className='w-full h-[40px] bg-secondary text-white rounded-lg'>
					<p>Add to Cart</p>
				</button>
			</div>
			<div className='mt-4'>
				<button
					onClick={handleCheckout}
					className='w-full h-[40px] border-[2px] border-secondary text-primary rounded-lg'>
					<p>Check Out Now</p>
				</button>
			</div>
		</div>
	);
};

export default EcomAddProduct;
