import React, { useEffect, useState } from "react";
import Container from "../../../utils/ecommerce/EcomContainer";
import profilePicture from "../../../assets/ecommerce/user/profile-pic.png";
import { VscDashboard } from "react-icons/vsc";
import { RiShoppingBag2Line } from "react-icons/ri";
import { GrDeliver } from "react-icons/gr";
import { FaRegStar } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import UserDashboard from "./components/UserDashboard";
import { FaRegUserCircle } from "react-icons/fa";
import UserProfile from "./components/UserProfile";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../../hooks/ecommerce/productSlice/userSlice";
import { useNavigate } from "react-router";
import { MdOutlineFileUpload } from "react-icons/md";
import AxiosInstance from "../../../axiosInstance";
import { setUserData } from "../../../hooks/ecommerce/productSlice/userDataSlice";
import UserOrder from "./components/UserOrder";
import UserShippingAddress from "./components/UserShippingAddress";

const EcomDashboard = () => {
	const userId = useSelector((state) => state.userInfo.userId);
	const [ProfilePicture, setProfilePicture] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [Toggle, setToggle] = useState(1);

	const updateToggle = (id) => {
		setToggle(id);
	};

	const logout = () => {
		dispatch(clearUser());
		localStorage.removeItem("token");
		navigate("/login");
	};

	const handleViewUserData = () => {
		AxiosInstance.get(`/auth/user/${userId}`)
			.then((res) => {
				const data = res.data;
				setProfilePicture(data.profilePicture);
				dispatch(setUserData(data));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		handleViewUserData();
	}, []);

	const handleChangeProfile = (e) => {
		console.log("Input changed", e.target.files[0]); // Debugging line
		const file = e.target.files[0];
		if (file) {
			const formData = new FormData();
			formData.append("profilePicture", file);
			AxiosInstance.post(`/auth/uploadProfilePicture/${userId}`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
				.then((res) => {
					const data = res.data;
					handleViewUserData();
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	return (
		<Container>
			<div className='flex gap-x-5 mx-auto p-5'>
				<div className='w-[250px] flex-shrink-0'>
					<div className='flex flex-col justify-center items-center gap-2'>
						<div className='w-[150px] bg-slate-200 flex justify-center items-center text-gray-400 h-[150px] rounded-full object-cover'>
							{ProfilePicture !== "" ? (
								<div className='relative rounded-full w-32 h-32 border-gray-300 flex justify-center items-center cursor-pointer group'>
									<img
										className='absolute inset-0 w-full h-full rounded-full object-cover'
										src={`https://fancymalabis.apodigi.com/profile/${ProfilePicture}`}
										alt='Profile'
									/>

									<input
										type='file'
										className='absolute inset-0 opacity-0 cursor-pointer'
										onChange={handleChangeProfile}
									/>

									<div className='absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
										<label className='relative flex flex-col items-center'>
											<input
												type='file'
												className='absolute inset-0 w-12 h-12 opacity-0 cursor-pointer'
												onChange={handleChangeProfile}
											/>
											<MdOutlineFileUpload size={40} className='text-white' />
										</label>
										<p className='mt-2 text-center text-sm text-white'>
											Upload Profile Picture
										</p>
									</div>
								</div>
							) : (
								<div className='relative rounded-full group w-32 h-32 border-2 border-dashed border-gray-300 rounded-full flex justify-center items-center flex-col text-center cursor-pointer'>
									<input
										type='file'
										className='absolute inset-0 w-full h-full rounded-full opacity-0 cursor-pointer'
										onChange={handleChangeProfile}
									/>
									<div className='flex justify-center items-center flex-col  transition-opacity duration-300'>
										<MdOutlineFileUpload size={40} className='text-gray-500' />
										<p className='mt-2 text-sm text-gray-500'>
											Upload Profile Picture
										</p>
									</div>
								</div>
							)}
						</div>
						<div className='w-full h-full rounded-sm'>
							<div
								onClick={() => updateToggle(1)}
								className={`mt-2 p-2 px-3 ${
									Toggle === 1 ? "bg-gray-500/40" : "bg-gray-500/10"
								} hover:bg-gray-500/40 duration-500 font-normal flex justify-start gap-3 items-center`}>
								<div className='w-8 h-8 flex items-center  text-gray-500 text-start text-lg justify-center rounded-full bg-white/40'>
									<VscDashboard />
								</div>
								<p className='text-start font-medium text-gray-500'>
									Dashboard
								</p>
							</div>
							<div
								onClick={() => updateToggle(2)}
								className={`mt-2 p-2 px-3 ${
									Toggle === 2 ? "bg-gray-500/40" : "bg-gray-500/10"
								} hover:bg-gray-500/40 duration-500 font-normal flex justify-start gap-3 items-center`}>
								<div className='w-8 h-8 flex items-center text-start text-lg text-gray-500 justify-center rounded-full bg-white/40'>
									<FaRegUserCircle />
								</div>
								<p className='text-start font-medium text-gray-500'>
									Manage Profile
								</p>
							</div>
							<div
								onClick={() => updateToggle(3)}
								className={`mt-2 p-2 px-3 ${
									Toggle === 3 ? "bg-gray-500/40" : "bg-gray-500/10"
								} hover:bg-gray-500/40 duration-500 font-normal flex justify-start gap-3 items-center`}>
								<div className='w-8 h-8 flex items-center text-start text-lg text-gray-500 justify-center rounded-full bg-white/40'>
									<RiShoppingBag2Line />
								</div>
								<p className='text-start font-medium text-gray-500'>
									Manage Orders
								</p>
							</div>
							<div
								onClick={() => updateToggle(4)}
								className={`mt-2 p-2 px-3 ${
									Toggle === 4 ? "bg-gray-500/40" : "bg-gray-500/10"
								} hover:bg-gray-500/40 duration-500 font-normal flex justify-start gap-3 items-center`}>
								<div className='w-8 h-8 flex items-center text-gray-500 text-start text-lg justify-center rounded-full bg-white/40'>
									<GrDeliver />
								</div>
								<p className='text-start font-medium text-gray-500'>
									Manage Address
								</p>
							</div>
							<div
								onClick={() => updateToggle(5)}
								className={`mt-2 p-2 px-3 ${
									Toggle === 5 ? "bg-gray-500/40" : "bg-gray-500/10"
								} hover:bg-gray-500/40 duration-500 font-normal flex justify-start gap-3 items-center`}>
								<div className='w-8 h-8 flex items-center text-start text-lg justify-center rounded-full bg-white/40'>
									<FaRegStar />
								</div>
								<p className='text-start font-medium text-gray-500'>
									My Reviews
								</p>
							</div>
							<div
								onClick={logout}
								className={`mt-2 p-2 px-3 ${
									Toggle === 6 ? "bg-gray-500/40" : "bg-gray-500/10"
								} hover:bg-gray-500/40 duration-500 font-normal flex justify-start gap-3 items-center cursor-pointer`}>
								<div className='w-8 h-8 flex items-center text-start text-lg justify-center rounded-full bg-white/40'>
									<IoMdLogOut />
								</div>
								<p className='text-start font-medium text-gray-500'>Logout</p>
							</div>
						</div>
					</div>
				</div>
				<div className='px-5 w-full h-auto'>
					{Toggle === 1 && <UserDashboard />}
					{Toggle === 2 && <UserProfile />}
					{Toggle === 3 && <UserOrder />}
					{Toggle === 4 && <UserShippingAddress />}
					{Toggle === 5 && <div>Tab 5</div>}
				</div>
			</div>
		</Container>
	);
};

export default EcomDashboard;
