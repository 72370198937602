import React from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setSearchValue } from "../../../../../../hooks/ecommerce/productSlice/searchSlice";

const EcomSearchbar = () => {
	const dispatch = useDispatch();
	const SearchValue = useSelector((state) => state.searchValue.searchValue);

	const handleChange = (e) => {
		dispatch(setSearchValue(e.target.value));
	};

	return (
		<div className='flex items-center rounded-md border px-1'>
			<div className=''>
				<input
					type='text'
					placeholder='Search Anything'
					className='w-[300px] lg:w-[500px] p-2 focus:outline-none  text-gray-500'
					onChange={handleChange}
					value={SearchValue}
				/>
			</div>

			<div className='text-xl p-1 bg-primary rounded-full text-white duration-300 hover:bg-secondary cursor-pointer'>
				<IoSearchOutline />
			</div>
		</div>
	);
};

export default EcomSearchbar;
