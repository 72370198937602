import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userData: {},
};

export const userDataslice = createSlice({
	name: "userData",
	initialState: initialState,
	reducers: {
		setUserData(state, action) {
			state.userData = action.payload;
		},
	},
});

export const { setUserData } = userDataslice.actions;

export default userDataslice.reducer;
