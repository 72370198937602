import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	AddressId: "",
};

export const shippingAddressSlice = createSlice({
	name: "AddressId",
	initialState,
	reducers: {
		setAddressId(state, action) {
			state.AddressId = action.payload;
		},
	},
});
export const { setAddressId } = shippingAddressSlice.actions;
export default shippingAddressSlice.reducer;
