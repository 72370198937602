import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AxiosInstance from "../../../../axiosInstance";
import { HiOutlineDotsVertical } from "react-icons/hi";

const OrderList = ({ Tab }) => {
	const [Data, setData] = useState([]);
	const userId = useSelector((state) => state.userInfo.userId);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		AxiosInstance.get(`/order/order-details/${userId}?delivery_status=${Tab}`)
			.then((res) => {
				setData(res.data);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
			});
	}, [userId, Tab]);

	return (
		<div>
			<div className=''>
				{isLoading ? (
					<p>Loading...</p>
				) : (
					Data.map((items, index) => {
						const orderTotal = items.products.reduce((sum, item) => {
							return sum + item.price * item.quantity;
						}, 0);
						const [date, timeWithTimeZone] = items.created_date.split("T");
						return (
							<div
								key={index}
								className='mb-3 bg-white overflow-hidden border rounded-lg'>
								<div className='px-3 py-2 bg-hover/10  flex justify-between items-center'>
									<div>
										<div className='flex gap-2'>
											<p className='text-lg font-semibold text-primary'>
												Order Id: {items.order_id}
											</p>
											<p className='text-lg font-semibold'>
												Total: ${orderTotal.toFixed(2)}
											</p>
										</div>
										<div className='flex gap-2 mt-3'>
											<p>
												<span className='font-semibold'>Date: </span>
												{date}
											</p>
											<p>
												<span className='font-semibold'>Time:</span>{" "}
												{items.created_time}
											</p>
											<p>
												<span className='font-semibold'>Delivery Status:</span>{" "}
												{items.delivery_status}
											</p>
										</div>
									</div>
									<div className=''>
										<HiOutlineDotsVertical />
									</div>
								</div>
								<div className='px-4 py-2'>
									{items.products.map((item, index) => {
										return (
											<div
												key={index}
												className='flex justify-between items-center border-b  mb-5'>
												<div className='flex gap-2 items-center'>
													<div className='w-[80px] h-[80px] object-contain'>
														<img
															className='w-full h-full object-contain'
															src={item.main_image}
															alt=''
														/>
													</div>
													<div className='w-[600px] break-words'>
														<p className='text-md font-medium'>
															{item.product_name} x {item.quantity}
														</p>
														<span className='px-3 text-xs bg-gray-400/50 rounded-full'>
															{item.category_name}
														</span>
														<span className='ml-2 px-3 text-xs bg-gray-400/50 rounded-full'>
															{item.product_brand}
														</span>
													</div>
												</div>
												<div>
													<p className='text-lg font-semibold text-primary'>
														Price: €{item.price}.00
													</p>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};

export default OrderList;
