import React from "react";

const AdminSidebar = () => {
	return (
		<div>
			<h1>Admin Sidebar</h1>
		</div>
	);
};

export default AdminSidebar;
