import { configureStore } from "@reduxjs/toolkit";
import productReducer from "../ecommerce/productSlice/productSlice";
import userReducer from "../ecommerce/productSlice/userSlice";
import cartReducer from "../ecommerce/productSlice/cartSlice";
import searchReducer from "../ecommerce/productSlice/searchSlice";
import userDataReducer from "../ecommerce/productSlice/userDataSlice";
import shippingAddressReducer from "../ecommerce/productSlice/shippingAddressSlice";

export const store = configureStore({
	reducer: {
		filterData: productReducer,
		userInfo: userReducer,
		cartData: cartReducer,
		searchValue: searchReducer,
		userData: userDataReducer,
		addressId: shippingAddressReducer,
	},
});
