import React, { useEffect, useState } from "react";
import OrderList from "./OrderList";
import { useSelector } from "react-redux";

const UserOrder = () => {
	const [Tab, setTab] = useState("");
	const handleChange = (e) => {
		setTab(e);
	};
	console.log(Tab);
	return (
		<div>
			<div className='flex gap-2'>
				<div className='px-4 py-1 bg-primary text-white rounded-md text-sm'>
					<button onClick={(e) => handleChange("")}>All</button>
				</div>
				<div className='px-4 py-1 bg-primary text-white rounded-md text-sm'>
					<button onClick={(e) => handleChange("processing")}>New</button>
				</div>
				<div className='px-4 py-1 bg-primary text-white rounded-md text-sm'>
					<button onClick={(e) => handleChange("in progress")}>
						In progress
					</button>
				</div>
				<div className='px-4 py-1 bg-primary text-white rounded-md text-sm'>
					<button onClick={(e) => handleChange("shipped")}>Shipped</button>
				</div>
				<div className='px-4 py-1 bg-primary text-white rounded-md text-sm'>
					<button onClick={(e) => handleChange("delivered")}>Delivered</button>
				</div>
				<div className='px-4 py-1 bg-primary text-white rounded-md text-sm'>
					<button onClick={(e) => handleChange("cancel")}>Cancel</button>
				</div>
			</div>
			<div className='mt-5'>
				<OrderList Tab={Tab} />
			</div>
		</div>
	);
};

export default UserOrder;
