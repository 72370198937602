import React from "react";
import LongBanner from "../../../../../../assets/ecommerce/banner/long.png";
const BannerLong = () => {
	return (
		<div className='mt-5 w-full h-full object-cover'>
			<img className='w-full h-full object-cover' src={LongBanner} alt='' />
		</div>
	);
};

export default BannerLong;
