import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DellLogo from "../../../../assets/ecommerce/brands/dell.png";
const EcomProductImage = ({ SingleProductData }) => {
	return (
		<div className='mt-5 px-24'>
			<div className='flex gap-2 mx-auto justify-center items-center'>
				<div className=''>
					<div className='w-[550px] h-[550px]  p-5 rounded-lg bg-gray-300'>
						<div className='w-full h-full bg-white flex justify-center items-center'>
							<img
								className='w-full h-full object-contain'
								src={SingleProductData.main_image}
								alt=''
							/>
						</div>
					</div>
				</div>
				<div className='w-[550px] h-[550px]  p-5 rounded-lg bg-gray-300'>
					<div className='w-full h-[430px] flex justify-center items-center overflow-hidden bg-white p-5'>
						<img
							className='w-full h-full object-cover rotate-12 animate-spin-slow'
							src={SingleProductData.main_image}
							alt=''
						/>
					</div>
					<div className='bg-white mt-3 py-2 px-3 rounded-md'>
						<Swiper
							spaceBetween={-90}
							slidesPerView={6} // Adjust the number as needed
						>
							{SingleProductData &&
								SingleProductData.product_images.map((item, index) => {
									return (
										<SwiperSlide key={index}>
											<div className='w-[50px] h-[50px] p-1 bg-gray-200'>
												<img
													className='w-full h-full object-contain'
													src={item.image_url}
													alt=''
												/>
											</div>
										</SwiperSlide>
									);
								})}
						</Swiper>
					</div>
				</div>
			</div>
			<div className='bg-white rounded-lg w-full py-3 text-center h-auto mt-5 flex items-center justify-center'>
				<div className='px-5'>
					<p className=' text-lg font-medium'>
						{SingleProductData.product_name}
					</p>
					<span className='text-xs bg-gray-300 px-4 rounded-full'>
						{SingleProductData.product_brand}
					</span>
					<span className='text-xs bg-gray-300 px-4 rounded-full ml-2'>
						{SingleProductData.category_name}
					</span>
				</div>
			</div>
		</div>
	);
};

export default EcomProductImage;
