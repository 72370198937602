import React from "react";
import { FaPlus } from "react-icons/fa6";
import EcomAddProduct from "./EcomAddProduct";
import EcomRecentProducts from "./EcomRecentProducts";

const EconProductDetails = ({ SingleProductData, ProductData }) => {
	return (
		<div className='mt-5 px-24'>
			<div className='flex gap-2'>
				<div className=' flex-1 bg-white p-4 rounded-lg'>
					<div>
						<p className='text-md font-semibold mb-2'>Details</p>
						<hr />
					</div>
					<div className='mt-6'>
						<div
							className='text-sm mb-8'
							dangerouslySetInnerHTML={{
								__html: SingleProductData.description,
							}}
						/>

						<hr />
					</div>

					<div className='w-full h-[400px] overflow-y-scroll'>
						<div className='mt-5'>
							<table className='w-full border p-2'>
								<tbody>
									{SingleProductData &&
										SingleProductData.product_specifications.map(
											(spec, index) =>
												index % 2 === 0 ? (
													<tr key={index} className='mb-6 p-2 border'>
														<td className='pl-5 py-1 pr-3 border'>
															<p className='text-sm'>
																<span className='font-[500] text-sm'>
																	{spec.specification}:{" "}
																</span>
																{spec.value}
															</p>
														</td>
														{SingleProductData.product_specifications[
															index + 1
														] && (
															<td className='pl-5 py-1'>
																<p className='text-sm'>
																	<span className='font-[500] text-sm'>
																		{" "}
																		{
																			SingleProductData.product_specifications[
																				index + 1
																			].specification
																		}
																	</span>
																	:{" "}
																	{
																		SingleProductData.product_specifications[
																			index + 1
																		].value
																	}
																</p>
															</td>
														)}
													</tr>
												) : null
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className='flex-1 bg-white p-4 rounded-lg'>
					<div className='text-2xl font-medium text-primary mb-3'>
						<p>€{SingleProductData.price}.00</p>
					</div>
					<EcomAddProduct />
					<EcomRecentProducts ProductData={ProductData} />
				</div>
			</div>
		</div>
	);
};

export default EconProductDetails;
