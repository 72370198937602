import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AxiosInstance from "../../../axiosInstance";
import Container from "../../../utils/ecommerce/EcomContainer";
import EcomProductImage from "./components/EcomProductImage";
import EconProductDetails from "./components/EconProductDetails";

const TestProduct = () => {
	const params = useParams();
	const [SingleProductData, setSingleProductData] = useState("");
	const { id } = params;
	const [ProductData, setProductData] = useState([]);

	useEffect(() => {
		AxiosInstance.get(`/product/eproducts/${id}`)
			.then((res) => {
				setSingleProductData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [id]);
	console.log(SingleProductData);

	useEffect(() => {
		AxiosInstance.get("/product/eproducts")
			.then((res) => {
				setProductData(res.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	return (
		<Container>
			<div className='mx-auto'>
				<EcomProductImage SingleProductData={SingleProductData} />
			</div>
			<div>
				<EconProductDetails
					ProductData={ProductData}
					SingleProductData={SingleProductData}
				/>
			</div>
		</Container>
	);
};

export default TestProduct;
