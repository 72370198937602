import React, { useEffect, useState } from "react";
import UserOrder from "./UserOrder";
import AxiosInstance from "../../../../axiosInstance";
import { useSelector } from "react-redux";

const UserDashboard = () => {
	const userId = useSelector((state) => state.userInfo.userId);

	const [Stats, setStats] = useState("");
	useEffect(() => {
		AxiosInstance.get(`/order/user-stats/${userId}`)
			.then((res) => {
				setStats(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [userId]);
	return (
		<div>
			<div className='w-full flex justify-center items-center gap-3'>
				<div className='w-[240px] h-[120px] bg-hover rounded-md p-2 flex items-center justify-center flex-col'>
					<p className='text-2xl font-bold'>{Stats.order_count}</p>
					<p className='text-md font-medium'>Total Orders</p>
				</div>
				<div className='w-[240px] h-[120px] bg-hover rounded-md p-2 flex items-center justify-center flex-col'>
					<p className='text-2xl font-bold'>{Stats.total_spent}</p>
					<p className='text-md font-medium'>Total Spend</p>
				</div>
				<div className='w-[240px] h-[120px] bg-hover rounded-md p-2 flex items-center justify-center flex-col'>
					<p className='text-2xl font-bold'>{Stats.wishlist_count}</p>
					<p className='text-md font-medium'>Wishlist</p>
				</div>
				<div className='w-[240px] h-[120px] bg-hover rounded-md p-2 flex items-center justify-center flex-col'>
					<p className='text-2xl font-bold'>{Stats.cart_count}</p>
					<p className='text-md font-medium'>Product in Cart</p>
				</div>
			</div>
			<div className='mt-5'>
				<p className='text-xl font-semibold'>Recent Order List</p>
				<UserOrder />
			</div>
		</div>
	);
};

export default UserDashboard;
