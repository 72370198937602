import React from "react";

const SingleProductReviews = () => {
	return (
		<div>
			<h1>SingleProductReviews</h1>
		</div>
	);
};

export default SingleProductReviews;
