import React, { useEffect, useState } from "react";
import AxiosInstance from "../../../axiosInstance";
import { useSelector } from "react-redux";
import Container from "../../../utils/ecommerce/EcomContainer";
import successIcon from "../../../assets/ecommerce/icons/success.gif";
import { useNavigate } from "react-router";
const CashOnDelivery = () => {
	const AddressId = useSelector((state) => state.addressId.AddressId);
	const navigate = useNavigate();
	const CartData = useSelector((state) => state.cartData.data) || []; // Default to empty array
	const userId = useSelector((state) => state.userInfo.userId);
	const [ProductArray, setProductArray] = useState([]);
	const [loading, setLoading] = useState(true); // State to manage loading status
	const [ShippingId, setShippingId] = useState("");
	useEffect(() => {
		if (Array.isArray(CartData) && CartData.length > 0) {
			const items = CartData.map((item) => ({
				product_id: item.product_id,
				quantity: item.quantity,
				sub_total: item.price * item.quantity,
			}));

			setProductArray(items);
		} else {
			console.error("CartData is not an array or is empty:", CartData);
		}
	}, [CartData]);
	useEffect(() => {
		const getId = localStorage.getItem("shipping_method");
		setShippingId(getId);
	}, []);
	useEffect(() => {
		if (ProductArray.length > 0 && userId) {
			const createOrder = async () => {
				try {
					const formData = {
						user_id: userId,
						shipping_method_id: AddressId,
						shipping_charge: 10.5,
						delivery_status: "Processing",
						payment_status: "Unpaid",
						payment_gateway: "Cash On Delivery",
						order_items: ProductArray,
					};

					await AxiosInstance.post("/order/create-order", formData);

					setTimeout(() => {
						window.location.href = "/order-invoice";
						localStorage.removeItem("shipping_method");
						localStorage.removeItem("payment_option");
					}, [5000]);
				} catch (error) {
					console.error("Error creating order:", error);
				} finally {
					setLoading(false);
				}
			};

			createOrder();
		}
	}, [ProductArray, userId]);

	if (loading) {
		return (
			<div className=' mt-20'>
				<Container>
					<div className='w-fit mx-auto '>
						<div className='bg-white rounded-lg drop-shadow-md p-10 my-auto mt-10  flex justify-center items-center flex-col '>
							<img src={successIcon} alt='' />
							<p>Processing your order...</p>
						</div>
					</div>
				</Container>
			</div>
		);
	}

	return (
		<div className=' mt-20'>
			<Container>
				<div className='w-fit mx-auto '>
					<div className='bg-white rounded-lg drop-shadow-md p-10 my-auto mt-10  flex justify-center items-center flex-col '>
						<img src={successIcon} alt='' />
						<p>Processing your order...</p>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default CashOnDelivery;
