import React from "react";

const SingleProductSpecification = ({ SingleProductData }) => {
	const filterProductsSpecification = (options) => {
		const groupSpecification = {};

		options.forEach((option) => {
			if (!groupSpecification[option.title]) {
				groupSpecification[option.title] = [];
			}
			groupSpecification[option.title].push({
				specification: option.specification,
				value: option.value,
			});
		});
		return groupSpecification;
	};

	const filteredSpecification = SingleProductData?.product_specifications
		? filterProductsSpecification(SingleProductData.product_specifications)
		: {};

	console.log(filteredSpecification);
	return (
		<div>
			<div className='mt-5'>
				<table className='w-full border p-2'>
					<tbody>
						{SingleProductData &&
							SingleProductData.product_specifications.map((spec, index) =>
								index % 2 === 0 ? (
									<tr key={index} className='mb-6 p-2 border'>
										<td className='pl-5 py-1 pr-3 border'>
											<p className='text-sm'>
												<span className='font-[500] text-sm'>
													{spec.specification}:{" "}
												</span>
												{spec.value}
											</p>
										</td>
										{SingleProductData.product_specifications[index + 1] && (
											<td className='pl-5 py-1'>
												<p className='text-sm'>
													<span className='font-[500] text-sm'>
														{" "}
														{
															SingleProductData.product_specifications[
																index + 1
															].specification
														}
													</span>
													:{" "}
													{
														SingleProductData.product_specifications[index + 1]
															.value
													}
												</p>
											</td>
										)}
									</tr>
								) : null
							)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default SingleProductSpecification;
