import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../../../axiosInstance";
import AddShippingAddress from "./AddShippingAddress";
import { AiOutlineCheck } from "react-icons/ai";
import { setAddressId } from "../../../../hooks/ecommerce/productSlice/shippingAddressSlice";

const ShippingAddress = () => {
	const dispatch = useDispatch();
	const [isAdd, setIsAdd] = useState(false);
	const [ShippingData, setShippingData] = useState([]);
	const [GetId, setGetId] = useState("");
	const userId = useSelector((state) => state.userInfo.userId);

	useEffect(() => {
		AxiosInstance.get(`/billing/billing-address/${userId}`)
			.then((res) => {
				setShippingData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [userId]);
	const handleShowDataAdd = () => {
		AxiosInstance.get(`/billing/billing-address/${userId}`)
			.then((res) => {
				setShippingData(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handleSelectAddress = (id) => {
		localStorage.setItem("shipping_method", id);
		const getId = localStorage.getItem("shipping_method");
		setGetId(getId);
		dispatch(setAddressId(getId));
	};
	useEffect(() => {
		const getId = localStorage.getItem("shipping_method");
		setGetId(getId);
	}, []);

	return (
		<div>
			<div className='flex justify-between items-center mb-2'>
				<div className='text-md font-semibold'>
					<p>Shipping Addresses</p>
				</div>
				<div>
					<button
						onClick={() => setIsAdd(!isAdd)}
						className='px-4 py-1 bg-primary text-white rounded-sm hover:bg-secondary text-sm duration-300'>
						Add New Address
					</button>
				</div>
			</div>
			<hr />
			<div className='py-3'>
				{isAdd ? (
					<AddShippingAddress
						handleShowDataAdd={handleShowDataAdd}
						setIsAdd={setIsAdd}
					/>
				) : (
					<>
						{ShippingData && ShippingData.length > 0 ? (
							<>
								<div className=''>
									{ShippingData.map((items, index) => {
										const isSelected = GetId === String(items.id); // Compare as strings
										return (
											<div
												key={index}
												className='w-full h-auto break-words bg-hover/20 rounded-lg relative cursor-pointer duration-300 hover:border hover:border-primary mt-5 px-4 py-2'
												onClick={() => handleSelectAddress(items.id)}
												style={{
													border: isSelected ? "1px solid #016268" : "none",
												}}>
												<div>
													{isSelected && (
														<div className='w-5 h-5 bg-green-500 flex justify-center items-center text-white absolute -right-2 -top-2 rounded-full'>
															<AiOutlineCheck />
														</div>
													)}
													<p className='font-medium mb-2'>{items.user_name}</p>
													<div className='flex gap-2 text-sm mb-2'>
														<p>
															{" "}
															<span className='font-medium'>Email:</span>{" "}
															{items.user_email}
														</p>
														<p>
															{" "}
															<span className='font-medium'>Phone:</span>{" "}
															{items.phone}
														</p>
													</div>
													<p className='text-sm'>
														<span className='font-medium'>Address:</span>{" "}
														{items.address}, {items.city_name},{" "}
														{items.state_name}, {items.country_name}
													</p>
												</div>
											</div>
										);
									})}
								</div>
							</>
						) : (
							<div className='py-5 flex justify-center items-center text-md text-gray-400/80'>
								No address found
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default ShippingAddress;
