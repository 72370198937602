import React from "react";

const AdminHeader = () => {
	return (
		<div>
			<h1>Admin Header</h1>
		</div>
	);
};

export default AdminHeader;
